(function ($) {
  //Fixed Menu
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.site-header').addClass("fixed-menu").fadeIn();
    } else {
      $('.site-header').removeClass("fixed-menu");
    }
  });

  //Home-title height 100%
  var height = window.innerHeight;
  var width = window.innerWidth;

  if (width < 780) {
    var height = height - 125;
  }

  $('.home-title').css('min-height', height + 'px');
  var middle = height * 0.5;
  //$('.home-title .widget_sow-editor').css('margin-top', '-' + middle + 'px');

})(jQuery);
